<template>
  <div>
    <div class="flex gap-2 justify-center">
      <router-link :to="{ name: 'SignUp' }">
        <button
          type="button"
          class="border px-3 py-1 rounded-md min-w-[245px]"
          :class="[
            this.$route.name == 'SignUp'
              ? 'bg-[#2A84EF] text-white'
              : 'bg-white text-gray-700',
          ]"
        >
          Personal
        </button>
      </router-link>
      <router-link :to="{ name: 'orgSignUp' }">
        <button
          type="button"
          class="border px-3 py-1 rounded-md min-w-[245px]"
          :class="[
            this.$route.name == 'orgSignUp'
              ? 'bg-[#2A84EF] text-white'
              : 'bg-white text-gray-700',
          ]"
        >
          Organization
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
