<template>
  <div class="bg-white md:h-screen flex flex-col justify-center items-center">
    <div
      class="bg-white flex flex-col gap-y-2 2xl:gap-y-3 relative rounded-lg w-full md:w-[75%] max-w-[500px]"
    >
      <img
        src="../assets/Logo.svg"
        alt="Logo"
        class="mx-auto w-auto h-8 md:h-10"
      />
      <h1 class="text-xl md:text-3xl font-semibold text-center roboto-medium">
        Sign in
      </h1>
      <h2 class="text-sm md:text-xl text-center roboto-regular">
        Welcome to LIVEYfy!
      </h2>
      <!-- <div class="flex gap-5 items-center gap-y-2 2xl:gap-y-5">
        <button>
          <img
            @click="gmaillogin"
            src="../assets/gmail.png"
            alt="gmail"
            class="w-auto"
          />
        </button>
        <button>
          <img src="../assets/Microsoft.png" alt="microsoft" class="w-auto" />
        </button>
      </div> -->
      <form
        @submit.prevent="loginUser()"
        class="flex flex-col gap-y-2 2xl:gap-y-5 roboto-light"
      >
        <!-- <div class="flex items-center">
          <span class="flex-grow border-b border-gray-300"></span>
          <p class="text-sm text-gray-500 px-2">or continue with email</p>
          <span class="flex-grow border-b border-gray-300"></span>
        </div> -->

        <div class="">
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3">
              <img
                src="../assets/mail.svg"
                alt="User"
                class="h-5 w-5 object-contain"
              />
            </div>
            <input
              id="email"
              type="text"
              name=""
              v-model="username"
              class="text-sm placeholder-gray-500 rounded-md pl-10 pr-4 border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
              placeholder="Enter your email / username"
            />
          </div>
        </div>
        <div class="">
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3">
              <img
                src="../assets/lock.svg"
                alt="User"
                class="h-5 w-5 object-contain"
              />
            </div>
            <input
              id="password"
              type="password"
              name=""
              v-model="password"
              class="text-sm placeholder-gray-500 pl-10 pr-4 rounded-md border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
              placeholder="Enter your password"
            />
          </div>
          <p
            v-if="this.message.text != ''"
            class="text-start"
            :class="[
              this.message.status == 200 ? ' text-green-700' : ' text-red-700',
            ]"
          >
            {{ this.message.text }}
          </p>
        </div>
        <button
          v-if="!loading"
          type="submit"
          class="w-full py-1 rounded-lg bg-[#2A84EF] text-white h-[49px]"
        >
          Sign in
        </button>
        <button
          v-else
          class="w-full py-1 rounded-lg bg-[#2A84EF] text-white h-[49px]"
        >
          Loading...
        </button>
        <router-link to="/forgotpassword">
          <div class="flex items-center justify-between">
            <span class="text-gray-700 text-sm">Forgot Password?</span>
            <span class="text-sm"> Reset </span>
          </div>
        </router-link>
      </form>
      <div class="flex flex-col gap-y-4 roboto-light">
        <div class="flex items-center p-2">
          <span class="flex-grow border-b border-gray-300"></span>
          <p class="text-sm text-gray-500 mx-3">Don’t have an account?</p>
          <span class="flex-grow border-b border-gray-300"></span>
        </div>

        <router-link :to="{ name: 'SignUp' }">
          <button
            class="bg-black text-white rounded-md py-1 text-center roboto-light h-[49px] w-full"
          >
            Sign up here
          </button>
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/FooterVIew.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
      message: {
        text: "",
        status: "",
      },
      loading: false,
    };
  },
  components: {
    Footer,
  },
  computed: {
    ...mapGetters(["getError", "isAuthenticated", "getUser", "getToken"]),
  },

  methods: {
    ...mapActions(["login"]),
    postUserLoginInfo() {
      let postJson = {
        latitude: 1,
        longitude: 1,
        login_source: "web",
        login_date_time: new Date(),
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/api/login-info/${this.getUser.id}/?Key=${this.getToken}`,
          postJson
        )
        .then((response) => {
          console.log(response, "info");
        });
    },
    async loginUser() {
      try {
        this.loading = true;
        await this.login({ username: this.username, password: this.password });
        if (this.isAuthenticated) {
          this.message.text = "Please wait...";
          this.message.status = 200;
          setTimeout(() => {
            this.message.text = "";
          }, 3000);
          this.postUserLoginInfo();
          if (this.getUser.is_superuser) {
            this.$router.push({ name: "AllUsersInfo" });
          } else {
            if (this.getUser.permission == "admin") {
              this.$router.push({ name: "AdminDash" });
            } else {
              this.$router.push({ name: "userdash" });
            }
          }
        } else {
          this.message.text = "Email ID and password do not match";
          this.message.status = 400;
          this.isActive = true;
          setTimeout(() => {
            this.message.text = "";
            this.isActive = false;
            this.loading = false;
          }, 3000);
        }
      } catch (error) {
        console.error("Login error:", error);
        this.message.text = "Email ID and password do not match";
        this.message.status = 400;
        this.isActive = true;
        setTimeout(() => {
          this.message.text = "";
          this.isActive = false;
          this.loading = false;
        }, 3000);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
