<template>
  <div class="flex h-screen roboto-light">
    <!-- Sidebar -->
    <div
      :class="[
        'bg-[#ffffff]',
        'text-white',
        'w-64',
        { hidden: isSidebarOpen },
        { 'md:block': isSidebarOpen },
      ]"
      class="w-64 flex flex-col"
    >
      <!-- Logo -->
      <div class="p-4 flex items-center">
        <img src="../assets/Logo.svg" alt="Logo" class="w-2/3" />
      </div>
      <!-- Navigation Links -->
      <div class="flex flex-col gap-y-1">
        <router-link
          :to="{ name: 'AllUsersInfo' }"
          class="flex items-center gap-x-3 py-2 pl-2 text-[#54595F] text-sm hover:bg-gray-100"
        >
          <img
            v-if="this.$route.name === 'AllUsersInfo'"
            src="../assets/dashboard_active.png"
            class=""
            alt=""
          />
          <img v-else src="../assets/dashboard.svg" class="" alt="" />
          Dashboard</router-link
        >
      </div>
      <div class="flex flex-col gap-y-1">
        <router-link
          :to="{ name: 'OrganizationsInfo' }"
          class="flex items-center gap-x-3 py-2 pl-2 text-[#54595F] text-sm hover:bg-gray-100"
        >
          <img
            v-if="this.$route.name === 'OrganizationsInfo'"
            src="../assets/dashboard_active.png"
            class=""
            alt=""
          />
          <img v-else src="../assets/dashboard.svg" class="" alt="" />
          Organization Info</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isSidebarOpen: true,
      isProfileMenuOpen: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters(["getUser", "isAuthenticated"]),
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleProfileMenu() {
      this.isProfileMenuOpen = !this.isProfileMenuOpen;
    },
    closeProfileMenu(event) {
      if (!event.target.closest(".relative")) {
        this.isProfileMenuOpen = false;
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.closeProfileMenu);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.closeProfileMenu);
  },
};
</script>
