<template>
  <div class="flex">
    <SideNav />
    <div class="bg-[#f1f8fe] h-screen w-full">
      <TopBar />
      <div class="h-full w-[98%] mx-auto py-6">
        <h1 class="py-2 text-xl font-semibold">Subscription Plan History</h1>
        <table class="w-full text-left bg-white">
          <thead class="p-5 text-gray-700">
            <tr class="">
              <th class="p-2 text-sm">S.No</th>
              <th class="p-2 text-sm">Name</th>
              <th class="p-2 text-sm">Email</th>
              <th class="p-2 text-sm">Plan Type</th>
              <th class="p-2 text-sm">Price</th>
              <th class="p-2 text-sm">Payment Date</th>
              <th class="p-2 text-sm">Expire Date</th>
            </tr>
          </thead>
          <tbody class="w-full">
            <template v-if="userHistory.length > 0">
              <tr
                class="text-left border-b"
                v-for="(info, index) in userHistory"
                :key="info"
              >
                <td class="p-2 text-sm">{{ index + 1 }}</td>
                <td class="p-2 text-sm">{{ info.user.first_name }}</td>
                <td class="p-2 text-sm">{{ info.user.email }}</td>
                <td class="p-2 text-sm">{{ info.plan.title }}</td>
                <td class="p-2 text-sm">$ {{ info.price }}</td>
                <td class="p-2 text-sm">
                  {{ new Date(info.created_at).toLocaleString() }}
                </td>
                <td class="p-2 text-sm">
                  {{ new Date(info.expire_at).toLocaleString() }}
                </td>
              </tr>
            </template>
            <template>
              <tr>
                <td colspan="5">No Data Found</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import SideNav from "@/components/SideNav.vue";
import TopBar from "@/components/TopBar.vue";
import axios from "axios";
import { useStore } from "vuex";
import { ref } from "vue";

const store = useStore();
const userHistory = ref([]);
billHistory();
async function billHistory() {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API}/api/bill-history/?user_id=${store.getters.getUser.id}`
    );
    userHistory.value = response.data.results;
  } catch (error) {
    console.log(error);
  }
}
</script>

<style></style>
