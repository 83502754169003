<template>
  <div class="flex">
    <SideNav />

    <div class="flex-1 bg-[#F1F8FE] w-full">
      <TopBar />
      <div class="h-[calc(100vh-60px)] overflow-y-auto">
        <div class="w-[90%] mx-auto">
          <h1 class="text-start text-3xl pt-6">User Details</h1>
          <table class="text-left rounded-md w-full">
            <thead class="p-5 text-white h-14">
              <tr class="bg-[#2a84ef] h-10 px-2">
                <th class="p-2 text-sm roboto-light font-medium text-center">
                  No
                </th>
                <th class="p-2 text-sm roboto-light font-medium">Name</th>
                <th class="p-2 text-sm roboto-light font-medium">User Name</th>
                <th class="p-2 text-sm roboto-light font-medium">Email</th>
                <th class="p-2 text-sm roboto-light font-medium">
                  Phone number
                </th>
                <th class="p-2 text-sm roboto-light font-medium">
                  Access Type
                </th>
                <th class="p-2 text-sm roboto-light font-medium">
                  Organization Name
                </th>
                <th class="p-2 text-sm roboto-light font-medium">
                  Joined Date
                </th>
              </tr>
            </thead>
            <tbody class="w-full">
              <template v-if="user_info">
                <tr
                  class="text-left py-2 border rounded-lg"
                  v-for="(user, index) in user_info"
                  :key="user"
                  :class="(index + 1) % 2 == 0 ? 'bg-gray-200' : 'bg-white'"
                >
                  <td class="p-2 text-sm text-center">
                    {{ index + 1 }}
                  </td>
                  <td class="flex p-2 items-center gap-x-2">
                    <span
                      class="px-2 py-1 rounded-full text-xs text-white bg-[#2a84ef] h-6 w-6 flex items-center justify-center capitalize"
                      >{{ user.first_name.slice(0, 1) }}
                    </span>
                    <p class="text-sm">{{ user.first_name }}</p>
                  </td>
                  <td class="p-2 text-sm">{{ user.username }}</td>
                  <td class="p-2 text-sm">{{ user.email }}</td>
                  <td class="p-2 text-sm">{{ user.phone }}</td>
                  <td class="p-2 text-sm">{{ user.Permission_access }}</td>
                  <td class="p-2 text-sm">{{ user.organization }}</td>
                  <td class="p-2 text-sm">
                    {{ new Date(user.date_joined).toLocaleString("en-GB") }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="text-center">
                  <td colspan="3">No data</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="mx-auto my-4">
          <PaginationViewVue
            @selectedPage="getUserInfo"
            :total_number_page="this.total_page"
            :count_per_page="this.count_per_page"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SideNav from "@/components/SideNavAdmin.vue";
import TopBar from "@/components/TopBar.vue";
import PaginationViewVue from "@/components/PaginationPageView.vue";
export default {
  data() {
    return {
      user_info: [],
      count_per_page: 30,
      total_page: "",
    };
  },

  components: { SideNav, TopBar, PaginationViewVue },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo(pageNumber = 1) {
      console.log(pageNumber);
      this.current_page = pageNumber;
      axios
        .get(
          `${process.env.VUE_APP_API}/api/list-users-all/?Key=0d27198fa776de1bec62f55ccb68ae199b477ce5&page=${this.current_page}`
        )
        .then((response) => {
          console.log(response);

          this.user_info = response.data.results;
          this.total_page = Math.round(
            response.data.count / this.count_per_page
          );
        });
    },
  },
};
</script>

<style></style>
