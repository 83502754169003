import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    token: null,
    isAuthenticated: false,
    error: null,
    user: null,
  },
  actions: {
    async login({ commit }, { username, password }) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API}/login/`, {
          username,
          password,
        });
        if (response.data.status === 200 && response.data.token_key) {
          commit("setUser", response.data.user);
          commit("setToken", response.data.token_key);
          commit("setIsAuthenticated", true);
          commit("setError", null);
        } else {
          throw new Error("Invalid credentials");
        }
      } catch (error) {
        commit("setError", error.message || "An error occurred during login");
        commit("setIsAuthenticated", false);
        commit("setUser", null);
        console.error("Error during login:", error);
      }
    },

    async signout({ commit }) {
      try {
        const token = localStorage.getItem("Token");

        if (!token) {
          throw new Error("No token available for logout.");
        }

        let config = {
          method: "get",
          url: `${process.env.VUE_APP_API}/logout/`,
          headers: {
            Authorization: `Token ${token}`,
          },
        };

        await axios.request(config);

        commit("signout");
        localStorage.removeItem("Token");
      } catch (error) {
        console.error("Logout error:", error);
      }
    },

    logout({ commit }) {
      commit("setToken", null);
      commit("setIsAuthenticated", false);
      commit("setUser", null);
      localStorage.removeItem("Token");
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    getToken: (state) => state.token,
    getError: (state) => state.error,
    getUser: (state) => state.user,
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    signout(state) {
      state.token = null;
      state.isAuthenticated = false;
      state.user = null;
    },
  },
  modules: {},
  plugins: [createPersistedState({})],
});
