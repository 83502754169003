<template>
  <div class="flex items-center justify-center h-screen">
    <div class="p-4 rounded shadow-lg ring ring-[#2A84EF]/50">
      <div class="flex flex-col items-center space-y-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-green-600 w-28 h-28"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h1 class="text-4xl font-bold">Thank You !</h1>
        <p>Thank you for your interest! Your payment was successful.</p>
        <a
          class="inline-flex items-center px-4 py-2 text-white bg-[#2A84EF] border border-[#2A84EF] rounded-full hover:bg-indigo-700 focus:outline-none focus:ring"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-3 h-3 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
          <a
            v-if="getUser.permission == 'admin'"
            href="/admin-dashboard"
            class="px-6 py-3 bg-blue-500 text-white rounded-lg inline-block hover:bg-blue-600 transition duration-300 ease-in-out"
            >Back to Dashboard</a
          >
          <a
            v-else-if="getUser.permission == 'external'"
            href="/user-dashboard"
            class="px-6 py-3 bg-blue-500 text-white rounded-lg inline-block hover:bg-blue-600 transition duration-300 ease-in-out"
            >Back to Dashboard</a
          >
          <a
            v-else
            href="/"
            class="px-6 py-3 bg-blue-500 text-white rounded-lg inline-block hover:bg-blue-600 transition duration-300 ease-in-out"
          >
            Home
          </a>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ThankYouView",
  computed: {
    ...mapGetters(["getUser"]),
  },
};
</script>

<style></style>
