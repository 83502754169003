<template>
  <div class="flex bg-gray-50 h-screen">
    <SideNav />

    <div class="w-full bg-[#f1f8fe]">
      <TopBar class="" />
      <section
        class="flex flex-col justify-center h-[80%] w-full md:w-[85%] items-center gap-y-3 relative"
      >
        <h1 class="text-lg md:text-3xl py-4">Download LIVEYfy App</h1>
        <img src="../assets/meeting.svg" class="h-60" alt="" />
        <div class="flex gap-2">
          <button
            @click="downloadDataPost('windows')"
            class="rounded-md bg-primary text-white gap-x-1 flex items-center p-2"
          >
            <font-awesome-icon icon="download" />
            <p>Download for Windows</p>
          </button>
          <button
            @click="downloadDataPost('mac')"
            class="hidden rounded-md bg-primary text-white gap-x-1 flex items-center p-2 min-w-[218px]"
          >
            <font-awesome-icon icon="download" />
            <p>Download for Mac</p>
          </button>
        </div>
        <!-- <a
          href="https://deb-public.s3.ap-south-1.amazonaws.com/LIVEYfy_0.3.5_aarch64.dmg"
          target="_blank"
        >
          <p>available for <button class="text-primary">Mac</button></p>
        </a> -->
        <button class="text-primary underline" @click="showRequirement">
          See System Requirements
        </button>

        <!-- Requirements section -->
        <div
          v-show="requirements"
          class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
        >
          <div class="absolute inset-0 bg-black opacity-50"></div>
          <div
            v-show="requirements"
            class="absolute bg-white w-full md:w-1/2 h-auto rounded-lg shadow-xl p-5"
          >
            <h1 class="text-xl">Requirements</h1>
            <div class="flex py-2">
              <button
                :class="[showMac ? 'bg-primary text-white' : 'bg-gray-100']"
                class="px-5 p-3 rounded-l-md min-w-[105px] hidden"
                @click="showMacSection"
              >
                Mac</button
              ><button
                :class="[showWindow ? 'bg-primary text-white' : 'bg-gray-100']"
                class="px-5 py-3 rounded-md min-w-[105px]"
                @click="showWindowsSection"
              >
                Windows
              </button>
            </div>
            <section class="border text-left h-auto p-5">
              <div v-show="showMac">
                <table class="border w-full h-full">
                  <tr>
                    <th class="border px-5">OS</th>
                    <td class="border px-5">
                      <li>macOS version 10.3 Onwards</li>
                    </td>
                  </tr>
                  <tr>
                    <th class="border px-5">CPU</th>
                    <td class="border px-5">
                      <li>Intel Core i5</li>
                      <li>Apple silicon chip</li>
                    </td>
                  </tr>
                  <tr>
                    <th class="border px-5">RAM</th>
                    <td class="border px-5"><li>8GB RAM or higher</li></td>
                  </tr>
                </table>
              </div>
              <div v-show="showWindow">
                <table class="border w-full h-full">
                  <tr>
                    <th class="border px-5">OS</th>
                    <td class="border px-5"><li>64 bit Windows 10</li></td>
                  </tr>
                  <tr>
                    <th class="border px-5">CPU</th>
                    <td class="border px-5">
                      <l>
                        <li>Intel core i5</li>
                        <li>AMD Ryzen 5</li>
                      </l>
                    </td>
                  </tr>
                  <tr>
                    <th class="border px-5">RAM</th>
                    <td class="border px-5"><li>8GB RAM or higher</li></td>
                  </tr>
                </table>
              </div>
            </section>
            <button class="absolute top-2 right-2 clbtn" @click="closeReq">
              Close
            </button>
          </div>
        </div>
      </section>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";
import TopBar from "@/components/TopBar.vue";
// import Footer from "@/components/FooterVIew.vue";
import axios from "axios";

import { mapGetters } from "vuex";

export default {
  name: "DownloadView",
  data() {
    return {
      requirements: false,
      showMac: false,
      showWindow: true,
    };
  },
  computed: { ...mapGetters(["getUser"]) },

  methods: {
    downloadDataPost(download_file) {
      var a = document.createElement("a");
      if (download_file === "mac") {
        a.href =
          "https://deb-public.s3.ap-south-1.amazonaws.com/LIVEYfy_0.3.5_aarch64.dmg";
        a.target = "_blank";
        a.click();
      } else {
        a.href =
          "https://deb-public.s3.ap-south-1.amazonaws.com/LIVEYfy_0.3.5_x64-setup-24-Aug-release.exe";
        a.target = "_blank";
        a.click();
      }
      let json_data = {
        user: this.getUser.id,
        device: download_file,
        download_date_time: new Date(),
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/api/create-download-history/`,
          json_data
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showRequirement() {
      this.requirements = true;
    },
    showMacSection() {
      this.showMac = true;
      this.showWindow = false;
    },
    showWindowsSection() {
      this.showMac = false;
      this.showWindow = true;
    },
    closeReq() {
      this.requirements = false;
    },
  },
  components: {
    SideNav,
    TopBar,
    // Footer,
  },
};
</script>

<style scoped>
tr,
td {
  padding: 28px;
}
</style>
