import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../views/LoginView.vue";
import DownloadPage from "../views/DownloadView.vue";
import SignUp from "@/views/SignUp.vue";
import UserDash from "@/views/UserDash.vue";
import SettingView from "@/views/SettingView.vue";
import ManualView from "@/views/ManualView.vue";
import AdminDash from "@/views/AdminDash.vue";
import ChangePassword from "@/views/ForgotPassword/ChangePassword.vue";
import ForgotPass1 from "../views/ForgotPassword/ResetMail.vue";
import UpdatePassword from "@/components/Settings/UpdatePassword.vue";
import PrivacyPolicy from "@/views/Terms & Conditions/PrivacyPolicy.vue";
import TermsandConditions from "@/views/Terms & Conditions/TermsandConditions.vue";
import store from "../store";
import SubscriptionView from "@/components/SubscriptionView.vue";
import PageNotFound from "@/views/PageNotFoundView.vue";
import AdminProfile from "@/views/AdminProfileView.vue";
import UserProfile from "@/views/EmployeeProfileView.vue";
import OrgSignUp from "@/views/OrgSignUpView.vue";
import DownloadHistory from "@/views/DownloadHistoryView.vue";
import PaymentView from "@/views/PaymentView.vue";
import ThankYouView from "@/views/ThankYouView.vue";
import AllUsersInfo from "@/views/admin/AllUserListView.vue";
import OrganizationListView from "@/views/admin/OrganizationListPage.vue";
import UserSubscription from "@/views/PaymentHistory.vue";
import ExpressCheckOut from "@/components/ExpressCheckOut.vue";
import PaymentSuccess from "@/views/SuccessView.vue";
const routes = [
  {
    path: "/",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPass1,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/organization/sign-up",
    name: "orgSignUp",
    component: OrgSignUp,
  },
  {
    path: "/download",
    name: "DownloadPage",
    meta: { requiresAuth: true },
    component: DownloadPage,
  },
  {
    path: "/reset-password/:id/:token",
    name: "ChangePassword",
    component: ChangePassword,
    props: true,
  },
  {
    path: "/user-dashboard",
    name: "userdash",
    meta: { requiresAuth: true, is_user: true },
    component: UserDash,
  },
  {
    path: "/manual",
    name: "ManualView",
    meta: { requiresAuth: true },
    component: ManualView,
  },
  {
    path: "/settings",
    name: "SettingView",
    meta: { requiresAuth: true, is_admin: true },
    component: SettingView,
  },
  {
    path: "/admin-dashboard",
    name: "AdminDash",
    meta: { requiresAuth: true, is_admin: true },
    component: AdminDash,
  },
  {
    path: "/update-password",
    name: "UpdatePassword",
    meta: { requiresAuth: true },
    component: UpdatePassword,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    name: "TermsandConditions",
    component: TermsandConditions,
  },
  {
    path: "/subscription-view",
    name: "SubscriptionView",
    meta: { requiresAuth: true },
    component: SubscriptionView,
  },
  {
    path: "/non-existent-page",
    name: "PageNotFound",
    component: PageNotFound,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: PageNotFound },
  {
    path: "/admin-profile",
    name: "AdminProfile",
    meta: { requiresAuth: true, is_admin: true },
    component: AdminProfile,
  },
  {
    path: "/profile",
    name: "UserProfile",
    meta: { requiresAuth: true },
    component: UserProfile,
  },
  {
    path: "/download-history/:id",
    name: "DownloadHistory",
    props: true,
    meta: { requiresAuth: true, is_admin: true },
    component: DownloadHistory,
  },
  {
    path: "/stripe-payment/:plan_type",
    name: "PaymentView",
    component: PaymentView,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: ThankYouView,
  },
  {
    path: "/all-users-info",
    name: "AllUsersInfo",
    component: AllUsersInfo,
    meta: { requiresAuth: true },
  },
  {
    path: "/organizations-info",
    name: "OrganizationsInfo",
    component: OrganizationListView,
    meta: { requiresAuth: true },
  },
  {
    path: "/subscriptions-info",
    name: "UserSubscriptionsInfo",
    component: UserSubscription,
    meta: { requiresAuth: true },
  },
  {
    path: "/express-check-out/:plan_type",
    name: "ExpressCheckOut",
    component: ExpressCheckOut,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/success",
    name: "PaymentSuccess",
    component: PaymentSuccess,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some(
      (record) => record.meta.requiresAuth && record.meta.is_admin
    )
  ) {
    if (!store.getters.isAuthenticated) {
      next("/");
    } else {
      if (
        store.getters.isAuthenticated &&
        store.getters.getUser.permission == "admin"
      ) {
        next();
      } else {
        next("/non-existent-page");
      }
    }
  } else if (
    to.matched.some((record) => record.meta.requiresAuth && record.meta.is_user)
  ) {
    if (!store.getters.isAuthenticated) {
      next("/");
    } else {
      if (
        store.getters.isAuthenticated &&
        (store.getters.getUser.permission == "external" ||
          store.getters.getUser.permission == "employee")
      ) {
        next();
        console.log("from.path", from.path);
      } else {
        next("/non-existent-page");
      }
    }
  } else {
    next();
  }
});
export default router;
