<template>
  <div class="flex">
    <SideNav />

    <div class="md:flex-1 md:max-w-full mx-auto bg-[#F1F8FE] w-full">
      <TopBar />
      <div class="p-4 md:px-8">
        <div class="block md:flex md:space-x-3 h-auto pt-10">
          <div
            class="w-full md:w-[65%] p-3 bg-white border rounded-xl leading-10 text-[14px] px-4 md:px-10 mb-5"
          >
            <p class="text-[#54595F] flex justify-start text-[18px] py-5">
              Over All Stats
            </p>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-10">
              <!-- Stat Cards -->
              <div class="block text-start w-full text-[#54595F]">
                <img
                  src="../assets/Vector.png"
                  alt=""
                  class="w-[20px] h-[20px] md:w-auto md:h-auto"
                />
                <p>Meeting Numbers</p>
                <b class="font-bold text-[28px] text-[#000000]">0</b>
              </div>
              <div class="block text-start w-full text-[#54595F]">
                <img
                  src="../assets/Group_178.png"
                  alt=""
                  class="w-[20px] h-[20px] md:w-auto md:h-auto"
                />
                <p>Meeting Hours Used</p>
                <b class="font-bold text-[28px] text-[#000000]">0 hrs</b>
              </div>
              <div class="block text-start w-full text-[#54595F]">
                <img
                  src="../assets/Group_181.png"
                  alt=""
                  class="w-[20px] h-[20px] md:w-auto md:h-auto"
                />
                <p>Total Device Used</p>
                <b class="font-bold text-[28px] text-[#000000]">0</b>
              </div>
            </div>
          </div>

          <!-- Second Column -->
          <div
            class="w-full md:w-[35%] p-3 bg-white border rounded-xl leading-10 text-[14px] px-4 md:px-10 mb-5 space-y-3"
          >
            <h1 class="text-start md:text-[21px] text-[#54595F] font-semibold">
              Recent Activity
            </h1>
            <!-- Recent Activity Items -->
            <div class="flex flex-col gap-y-5 text-sm">
              <div class="flex text-start gap-x-2 border-b p-2">
                <img
                  src="../assets/Vector2.png"
                  class="h-5 w-5 object-contain"
                  alt=""
                />
                <p>0 New Users Registered</p>
              </div>
              <div class="flex text-start gap-x-2 border-b p-2">
                <img
                  src="../assets/Vector2.png"
                  class="h-5 w-5 object-contain"
                  alt=""
                />
                <p>Livey users engaged in 0 meetings</p>
              </div>
              <div class="flex text-start gap-x-2 border-b p-2">
                <img
                  src="../assets/Vector2.png"
                  class="h-5 w-5 object-contain"
                  alt=""
                />
                <p>0 users are currently utilizing noise cancellation</p>
              </div>
            </div>
          </div>
        </div>

        <div class="md:flex md:gap-x-5 md:w-[100%] w-full">
          <div
            class="w-full bg-white h-auto border py-5 rounded-xl p-5 mt-5 overflow-x-scroll md:overflow-x-hidden"
          >
            <!-- Search and Select -->
            <div class="md:flex justify-between w-full">
              <div>
                <h1>Total Calls</h1>
                <p class="font-bold text-2xl">0</p>
              </div>
              <div
                class="flex flex-col md:flex-row gap-y-1 gap-x-2 justify-center"
              >
                <div
                  class="flex relative items-center h-8 w-auto outline-none gap-x-2 px-2 border rounded-md bg-gray-100"
                >
                  <img
                    src="../assets/search.png"
                    class="h-4 w-4"
                    alt=""
                  /><input
                    type="text"
                    placeholder="Search..."
                    class="outline-none bg-gray-100 w-full md:w-auto"
                  />
                </div>
                <select
                  name=""
                  id=""
                  class="rounded-md px-2 h-8 w-full md:w-auto"
                >
                  <option value="">Today</option>
                  <option value="">Last Week</option>
                  <option value="">Last Month</option>
                </select>
              </div>
            </div>

            <!-- Table -->
            <div class="overflow-x-auto">
              <table
                class="w-full text-left border-b text-[14px] px-5 md:px-10"
              >
                <!-- Table Headings -->
                <thead class="p-3 md:p-5 text-gray-700">
                  <tr class="text-[14px] text-gray-700">
                    <th class="p-2">Customer</th>

                    <th class="p-2">Meeting Name</th>
                    <th class="p-2">
                      <select
                        name=""
                        id=""
                        class="py-2 text-gray-700 bg-white text-[14px]"
                      >
                        <option value="">Device Type</option>
                        <option value="">Desktop</option>
                        <option value="">Mobile</option>
                      </select>
                    </th>
                    <th class="p-2">Call Duration</th>
                    <th class="p-2">Timing Stamp</th>
                  </tr>
                </thead>
                <!-- Table Body -->
                <tbody>
                  <!-- Table Rows -->
                  <tr class="text-left border-b hidden">
                    <td class="p-2">
                      <div class="md:flex md:items-center gap-x-2">
                        <img
                          src="../assets/Rectangle_313.png"
                          alt=""
                          class="w-8 h-8"
                        />
                        <p>Santhosh</p>
                      </div>
                    </td>
                    <td class="p-2">LIVEYfy Catch up</td>
                    <td class="p-2">Desktop</td>
                    <td class="p-2">1hr 12min</td>
                    <td class="p-2">11/04/2024 11:40AM</td>
                  </tr>
                  <tr class="text-left border-b hidden">
                    <td class="p-2">
                      <div class="md:flex md:items-center gap-x-2">
                        <img
                          src="../assets/Rectangle_313.png"
                          alt=""
                          class="w-8 h-8"
                        />
                        <p>Santhosh</p>
                      </div>
                    </td>
                    <td class="p-2">LIVEYfy Catch up</td>
                    <td class="p-2">Desktop</td>
                    <td class="p-2">1hr 12min</td>
                    <td class="p-2">11/04/2024 11:40AM</td>
                  </tr>
                  <tr>
                    <td colspan="5" class="text-center p-5">No Data Found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";
import TopBar from "@/components/TopBar.vue";
export default {
  components: { SideNav, TopBar },
};
</script>
