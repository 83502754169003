<template>
  <div
    class="bg-white md:h-screen flex flex-col justify-center items-center gap-y-5 p-10 relative"
  >
    <!-- <div
      v-if="this.message.text != ''"
      class="absolute top-3 right-2 p-2 rounded-md w-[320px] h-[60px] bg-white shadow-lg text-center flex items-center justify-center font-semibold capitalize"
      :class="[
        this.message.status == 200
          ? 'border border-green-400'
          : 'border border-red-400',
      ]"
    >
      {{ this.message.text }}
    </div> -->
    <img src="../../assets/Logo.svg" class="h-8 md:h-10" alt="" />
    <div class="flex flex-col gap-y-3">
      <h1 class="text-2xl font-bold text-center">Reset Password</h1>
      <p class="text-sm">Regain Access to Your Account Securely</p>
    </div>
    <form
      @submit.prevent="resetPassword"
      class="flex flex-col gap-y-5 w-44 md:w-80"
    >
      <div
        class="flex w-full items-center border border-gray-400 rounded-md gap-x-2 p-1 relative"
      >
        <img src="../../assets/mail.svg" class="h-5 w-5" alt="" />
        <input
          type="email"
          placeholder="Enter your register email"
          name=""
          v-model="email"
          required
          class="outline-none rounded-md w-[80%] placeholder:text-sm"
          id=""
        />
      </div>
      <p
        v-if="this.message.text != ''"
        class="text-start"
        :class="[
          this.message.status == 200 ? ' text-green-700' : ' text-red-700',
        ]"
      >
        {{ this.message.text }}
      </p>

      <button
        v-if="!loading"
        type="submit"
        @click="showOTP"
        class="bg-[#2A84EF] rounded-md text-white p-1"
      >
        Reset Password
      </button>
      <button v-else class="bg-[#2A84EF] rounded-md text-white p-1">
        Loading...
      </button>
      <router-link to="/" class="border rounded-md p-1 text-center"
        >Cancel</router-link
      >
    </form>
  </div>
  <Footer />
</template>

<script>
import Footer from "@/components/FooterVIew.vue";
import axios from "axios";
export default {
  name: "ResetMail",
  data() {
    return {
      email: "",
      message: {
        text: "",
        status: "",
      },
      loading: false,
    };
  },
  components: {
    Footer,
  },
  methods: {
    resetPassword() {
      this.loading = true;
      let json_data = {
        email: this.email,
      };
      axios
        .post(`${process.env.VUE_APP_API}/forgot-password/`, json_data)
        .then((response) => {
          if (response.data.status == 200) {
            this.message.text =
              "A password reset link has been sent to your email. Please check your inbox to proceed.";
            this.message.status = 200;
            setTimeout(() => {
              this.message.text = "";
              this.message.status = "";
              (this.loading = false), this.$router.push("/");
            }, 4000);
          } else {
            this.message.text = response.data.message;
            this.message.status = 400;
            this.loading = false;
            setTimeout(() => {
              this.message.text = "";
              (this.loading = false), (this.message.status = "");
            }, 5000);
          }
        });
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    },
  },
};
</script>

<style></style>
