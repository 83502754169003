<template>
  <div>
    <ol class="v_pg max-w-[720px] mx-auto flex justify-center gap-2">
      <li v-if="this.previous_page">
        <button @click="previousSetVal" class="v_p_n">Previous</button>
      </li>
      <li v-if="this.current_page != 1">
        <p @click="previousPageCall" class="v_chevron v_tc">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="size-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </p>
      </li>
      <template v-for="i in pages" :key="i">
        <li
          v-if="i <= this.total_number_page"
          class="cursor-pointer"
          @click="(this.current_page = i), this.$emit('selectedPage', i)"
        >
          <p
            class=""
            :class="this.current_page == i ? 'v_pno_active' : 'v_pno'"
          >
            {{ i }}
          </p>
        </li>
      </template>

      <li v-if="this.current_page != this.total_number_page">
        <p @click="nextPageCall" class="v_chevron v_tc">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="size-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </p>
      </li>
      <li v-if="this.next_page">
        <button class="v_p_n" @click="nextSetVal">Next</button>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: ["total_number_page", "count_per_page"],
  data() {
    return {
      start_val: 1,
      end_val: this.count_per_page,
      next_page: false,
      previous_page: false,
      current_page: 1,
    };
  },
  computed: {
    pages() {
      return Array.from(
        { length: this.end_val - this.start_val + 1 },
        (_, i) => i + this.start_val
      );
    },
  },
  created() {
    this.total_number_page > this.count_per_page
      ? (this.next_page = true)
      : (this.next_page = false);
  },
  methods: {
    nextPageCall() {
      if (this.current_page <= this.total_number_page) {
        this.current_page = this.current_page + 1;
        if (this.current_page > this.pages.at(-1)) {
          this.nextSetVal();
        }
        this.$emit("selectedPage", this.current_page);
      }
    },
    previousPageCall() {
      if (this.current_page > 1) {
        this.current_page = this.current_page - 1;
        if (this.current_page < this.pages.at(0)) {
          this.previousSetVal();
        }
        this.$emit("selectedPage", this.current_page);
      }
    },
    nextSetVal() {
      if (this.total_number_page > this.end_val) {
        this.start_val += this.count_per_page;
        this.end_val += this.count_per_page;
        this.previous_page = true;
        this.current_page = this.pages.at(0);
        this.$emit("selectedPage", this.current_page);
      }
      if (this.end_val > this.total_number_page) {
        this.next_page = false;
      }
    },
    previousSetVal() {
      if (this.start_val > this.count_per_page) {
        this.start_val -= this.count_per_page;
        this.end_val -= this.count_per_page;
        this.next_page = true;
        if (this.start_val == 1) {
          this.previous_page = false;
        }
        this.current_page = this.pages.at(-1);
        this.$emit("selectedPage", this.current_page);
      } else {
        this.previous_page = false;
      }
    },
  },
};
</script>

<style scoped>
.v_pg {
  display: flex;
  gap: 4px;
}

.v_p_n {
  border: 1px solid #f1f5f9;
  padding: 4px 6px;
  border-radius: 10px;
}
.v_chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #f1f5f9;
  color: #64748b;
  width: 2rem;
  height: 2rem;
}
.v_pno {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: white;
  border: 1px solid #f1f5f9;
  color: #64748b;
  width: 2rem;
  height: 2rem;
}
.v_pno_active {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: #0369a1;

  color: #fff;
  width: 2rem;
  height: 2rem;
}
.v_pno:hover {
  background-color: #cbd5e1;
}
.v_chevron:hover {
  background-color: #cbd5e1;
}
.v_tc {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
</style>
