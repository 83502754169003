<template>
  <div class="mx-auto max-w-[1200px] p-5 flex flex-col gap-2">
    <img
      src="../../assets/Logo.svg"
      class="h-20 w-full bg-gray-200 p-2"
      alt=""
    />

    <div>
      <h1 class="text-lg font-normal">Privacy Policies</h1>
      <p class="text-gray-700 text-sm">
        &nbsp; This Privacy Policy governs the manner in which Livey
        Technologies LLC collects, uses, maintains and discloses information
        collected from users (each, a “User”) of the www.livey-tech.com website
        (“Site”). This privacy policy applies to the Site and all products and
        services offered by Livey Technologies LLC.
      </p>
    </div>

    <div>
      <h1 class="text-lg font-normal">Personal identification information</h1>
      <p class="text-gray-700 text-sm">
        &nbsp; We may collect personal identification information from Users in
        a variety of ways, including, but not limited to, when Users visit our
        site, register on the site place an order fill out a form respond to a
        survey subscribe to the newsletter and in connection with other
        activities, services, features or resources we make available on our
        Site. Users may be asked for, as appropriate, name, email address,
        mailing address, phone number, credit card information, social security
        number Users may, however, visit our Site anonymously.
      </p>
    </div>

    <div>
      <p class="text-gray-700 text-sm">
        &nbsp; We will collect personal identification information from Users
        only if they voluntarily submit such information to us. Users can always
        refuse to supply personally identification information, except that it
        may prevent them from engaging in certain Site related activities.
      </p>
    </div>

    <div>
      <h1 class="text-lg font-normal">
        Non-personal identification information
      </h1>
      <p class="text-gray-700 text-sm">
        &nbsp; We may collect non-personal identification information about
        Users whenever they interact with our Site. Non-personal identification
        information may include the browser name, the type of computer and
        technical information about Users means of connection to our Site, such
        as the operating system and the Internet service providers utilized and
        other similar information.
      </p>
    </div>

    <div>
      <h1 class="text-lg font-normal">Web browser cookies</h1>
      <p class="text-gray-700 text-sm">
        &nbsp; Our Site may use “cookies” to enhance User experience. User’s web
        browser places cookies on their hard drive for record-keeping purposes
        and sometimes to track information about them. User may choose to set
        their web browser to refuse cookies, or to alert you when cookies are
        being sent. If they do so, note that some parts of the Site may not
        function properly.
      </p>
    </div>

    <div>
      <p class="text-gray-700 text-sm">
        &nbsp;How we use collected information
      </p>
    </div>

    <div></div>

    <h3 class="text-gray-700 text-sm">
      Livey Technologies LLC collects and uses Users personal information for
      the following purposes:
      <li class="text-gray-700 text-sm">To improve customer service</li>
    </h3>

    <div></div>

    <h3 class="text-gray-700 text-sm">
      Your information helps us to more effectively respond to your customer
      service requests and support needs.
      <li class="text-gray-700 text-sm">To personalize user experience</li>
    </h3>

    <div></div>

    <h3 class="text-gray-700 text-sm">
      We may use information in the aggregate to understand how our Users as a
      group use the services and resources provided on our Site.
      <li class="text-gray-700 text-sm">To improve our Site</li>
    </h3>

    <div></div>

    <h3 class="text-gray-700 text-sm">
      We continually strive to improve our website offerings based on the
      information and feedback we receive from you.
      <li class="text-gray-700 text-sm">To process transactions</li>
    </h3>

    <div></div>

    <h3 class="text-gray-700 text-sm">
      We may use the information Users provide about themselves when placing an
      order only to provide service to that order. We do not share this
      information with outside parties except to the extent necessary to provide
      the service.
      <li class="text-gray-700 text-sm">
        To administer a content, promotion, survey or other Site feature
      </li>
    </h3>

    <div></div>

    <h3 class="text-gray-700 text-sm">
      To send Users information they agreed to receive about topics we think
      will be of interest to them.
      <li class="text-gray-700 text-sm">
        Sharing your information with third parties
      </li>
    </h3>

    <div></div>

    <h3 class="text-gray-700 text-sm">
      We will not share or sell your information with third parties for
      marketing or other purposes.
      <li class="text-gray-700 text-sm">To send periodic emails</li>
    </h3>

    <div></div>

    <h3 class="text-gray-700 text-sm">
      The email address Users provide for order processing, will only be used to
      send them information and updates pertaining to their order. It may also
      be used to respond to their inquiries, and/or other requests or questions.
      If User decides to opt-in to our mailing list, they will receive emails
      that may include company news, updates, related product or service
      information, etc. If at any time the User would like to unsubscribe from
      receiving future emails, we include detailed unsubscribe instructions at
      the bottom of each email or User may contact us via our Site.
    </h3>

    <div></div>

    <h1 class="text-lg font-normal">How we protect your information</h1>
    <p class="text-gray-700 text-sm">
      &nbsp; We adopt appropriate data collection, storage and processing
      practices and security measures to protect against unauthorized access,
      alteration, disclosure or destruction of your personal information,
      username, password, transaction information and data stored on our Site.
    </p>

    <div></div>

    <p class="text-gray-700 text-sm">
      &nbsp; Sensitive and private data exchange between the Site and its Users
      happens over a SSL secured communication channel and is encrypted and
      protected with digital signatures. Our Site is also in compliance with PCI
      vulnerability standards in order to create as secure of an environment as
      possible for Users.
    </p>

    <div></div>

    <h1 class="text-lg font-normal">Third party websites</h1>
    <p class="text-gray-700 text-sm">
      &nbsp; Users may find advertising or other content on our Site that link
      to the sites and services of our partners, suppliers, advertisers,
      sponsors, licensors and other third parties. We do not control the content
      or links that appear on these sites and are not responsible for the
      practices employed by websites linked to or from our Site. In addition,
      these sites or services, including their content and links, may be
      constantly changing. These sites and services may have their own privacy
      policies and customer service policies. Browsing and interaction on any
      other website, including websites which have a link to our Site, is
      subject to that website\’s own terms and policies.
    </p>
    <h1 class="text-lg font-normal">Advertising</h1>
    <p class="text-gray-700 text-sm">
      &nbsp; Ads appearing on our site may be delivered to Users by advertising
      partners, who may set cookies. These cookies allow the ad server to
      recognize your computer each time they send you an online advertisement to
      compile non personal identification information about you or others who
      use your computer. This information allows ad networks to, among other
      things, deliver targeted advertisements that they believe will be of most
      interest to you. This privacy policy does not cover the use of cookies by
      any advertisers.
    </p>

    <div></div>

    <h1 class="text-lg font-normal">Google Adsense</h1>
    <p class="text-gray-700 text-sm">
      &nbsp; Some of the ads may be served by Google. Google\’s use of the DART
      cookie enables it to serve ads to Users based on their visit to our Site
      and other sites on the Internet. DART uses “non personally identifiable
      information” and does NOT track personal information about you, such as
      your name, email address, physical address, etc. You may opt out of the
      use of the DART cookie by visiting the Google ad and content network
      privacy policy at http://www.google.com/privacy_ads.html
    </p>

    <div></div>

    <h1 class="text-lg font-normal">
      Compliance with children’s online privacy protection act
    </h1>
    <p class="text-gray-700 text-sm">
      &nbsp; Protecting the privacy of the very young is especially important.
      For that reason, we never collect or maintain information at our Site from
      those we actually know are under 13, and no part of our website is
      structured to attract anyone under 13.
    </p>

    <div></div>

    <h1 class="text-lg font-normal">Changes to this privacy policy</h1>
    <p class="text-gray-700 text-sm">
      &nbsp; Livey Technologies LLC has the discretion to update this privacy
      policy at any time. When we do, post a notification on the main page of
      our Site,. We encourage Users to frequently check this page for any
      changes to stay informed about how we are helping to protect the personal
      information we collect. You acknowledge and agree that it is your
      responsibility to review this privacy policy periodically and become aware
      of modifications.
    </p>

    <div></div>

    <h1 class="text-lg font-normal">Your acceptance of these terms</h1>
    <p class="text-gray-700 text-sm">
      &nbsp; By using this Site, you signify your acceptance of this policy and
      terms of service. If you do not agree to this policy, please do not use
      our Site. Your continued use of the Site following the posting of changes
      to this policy will be deemed your acceptance of those changes.
    </p>

    <div></div>

    <h1 class="text-lg font-normal">Contacting Us</h1>
    <p class="text-gray-700 text-sm">
      &nbsp;If you have any questions about this Privacy Policy, the practices
      of this site, or your dealings with this site, please contact us at:
      <a
        class="text-blue-600 underline"
        target="_blank"
        href="https://www.liveyfy.com/"
        >LIVEYfy.com</a
      >
    </p>

    <div></div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
