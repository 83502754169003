<template>
  <div class="flex">
    <SideNav />
    <div class="bg-[#f1f8fe] h-screen w-full">
      <TopBar />
      <div class="h-full w-full flex items-center justify-center">
        <div
          class="flex flex-col p-4 md:px-8 max-w-[820px] min-h-[650px] w-full mx-auto rounded-lg bg-white"
        >
          <!-- <h2
            for="auto-audio"
            class="font-semibold text-lg text-gray-700 flex gap-4"
          >
            Profile
          </h2> -->
          <div>
            <div class="">
              <div class="border-b flex flex-col gap-6">
                <!-- Profile -->
                <div class="flex items-start gap-2 pb-2">
                  <div class="flex flex-col gap-2 py-1 w-full">
                    <!-- <h2
                      for="auto-audio"
                      class="font-semibold text-lg text-gray-700 flex gap-4"
                    >
                      Profile
                    </h2> -->
                    <div
                      v-if="profile_info.display_name"
                      class="flex items-end w-full gap-4 py-4 relative"
                    >
                      <h1
                        class="h-[120px] w-[120px] bg-[#2a84ef] uppercase rounded-full flex items-center justify-center text-5xl font-bold text-white"
                      >
                        {{ profile_info.display_name.slice(0, 1) }}
                      </h1>
                      <div class="py-4 px-2">
                        <p class="text-gray-700 text-base">
                          <span class="capitalize">{{
                            profile_info.display_name
                          }}</span>
                          <span class="text-sm">
                            (
                            {{
                              profile_info.username
                                ? profile_info.username
                                : "--"
                            }}
                            )
                          </span>
                        </p>
                        <p class="text-gray-700 text-base">
                          {{ profile_info.email }}
                        </p>
                        <p class="text-[#2a84ef] text-base">
                          {{
                            profile_info.organization_details[0]
                              .organizational_name
                          }}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <!-- <p class="text-gray-700 text-base font-normal">
                      Phone no : {{ profile_info.email }}
                    </p>
                    <p class="text-gray-700 text-base font-normal">
                      License : {{ profile_info.email }}
                    </p> -->
                  </div>
                </div>
                <!-- Change Password -->
                <div class="flex items-start gap-2">
                  <div class="flex flex-col gap-2">
                    <h2
                      for="auto-audio"
                      class="text-lg text-gray-700 flex gap-4 font-normal"
                    >
                      Password
                    </h2>
                    <p class="text-gray-700 text-base">
                      Update your password to keep your account secure. Ensure
                      it's strong and unique!.
                    </p>
                    <router-link :to="{ name: 'UpdatePassword' }">
                      <button
                        class="w-[170px] h-[40px] border border-[#54595F] bg-white rounded-[5px] text-gray-700 flex gap-2 items-center justify-center"
                      >
                        <img src="../assets/password-reset.png" alt="" />
                        Change Password
                      </button>
                    </router-link>
                  </div>
                </div>

                <div class="flex items-start gap-2">
                  <div class="flex flex-col gap-2 pt-1">
                    <h2
                      for="auto-audio"
                      class="text-lg text-gray-700 flex gap-4 font-normal"
                    >
                      Subscription details
                    </h2>
                    <p class="text-gray-700 text-base">
                      Choose the subscription plan that best suits your needs
                      and enjoy uninterrupted focus, crystal clear
                      communication, and a serene workspace.
                    </p>
                  </div>
                </div>

                <div
                  class="w-[90%] h-[140px] bg-[#F2F8FF] p-4 flex justify-between relative"
                >
                  <div class="flex flex-col justify-between h-full items-start">
                    <div>
                      <h1
                        class="text-[#2A84EF] font-semibold text-base capitalize"
                      >
                        {{ profile_info.subscription }} Subscription
                      </h1>
                      <p class="text-gray-700">
                        Valid till -
                        <span
                          :class="[
                            new Date(profile_info.ExpiryDate) < new Date()
                              ? 'text-red-700'
                              : '',
                          ]"
                          >{{
                            new Date(profile_info.ExpiryDate).toDateString()
                          }}</span
                        >
                        <span
                          v-if="new Date(profile_info.ExpiryDate) < new Date()"
                          class="text-red-700 text-xs"
                        >
                          (Expired)</span
                        >
                      </p>
                    </div>
                    <router-link :to="{ name: 'SubscriptionView' }">
                      <button
                        class="flex gap-2 items-center bg-black w-[140px] h-[40px] rounded-[5px] text-white justify-center"
                      >
                        <img src="../assets/upgrade-icon.png" alt="" />
                        <span>Upgrade</span>
                      </button>
                    </router-link>
                  </div>

                  <img
                    src="../assets/headset.png"
                    alt=""
                    class="h-[140px] w-[140px] object-contain absolute top-0 right-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";
import TopBar from "@/components/TopBar.vue";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "AdminProfile",
  data() {
    return {
      profile_info: [],
    };
  },
  computed: {
    ...mapGetters(["getUser", "getToken"]),
  },
  components: { SideNav, TopBar },
  created() {
    this.profileInfoCall();
  },
  methods: {
    profileInfoCall() {
      axios
        .get(`${process.env.VUE_APP_API}/profile/?Key=${this.getToken}`)
        .then((response) => {
          console.log(response);
          this.profile_info = response.data;
        });
    },
  },
};
</script>

<style></style>
