<template>
  <!-- <nav>
   <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> 
  </nav> -->
  <router-view />
</template>

<style lang="scss">
body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #54595f;
      font-weight: bold;
    }
  }
}
</style>
