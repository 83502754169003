<template>
  <div class="flex">
    <SideNav />

    <div class="bg-[#F1F8FE] h-screen w-full flex flex-col gap-y-2">
      <TopBar />
      <div class="flex justify-center items-center h-full">
        <div class="bg-white p-5 rounded-lg space-y-2 w-[40%]">
          <h1 class="text-2xl text-center">Change Password</h1>
          <p class="text-sm text-center text-wrap">
            Secure your account by updating your password to a new,
            <strong>strong</strong> one to enhance your
            <strong>online safety.</strong>
          </p>
          <form @submit.prevent="updatePassword" class="flex flex-col gap-4">
            <div class="flex flex-col gap-y-1">
              <label for=""
                >Old Password<sup class="text-red-500">*</sup></label
              >
              <div
                class="flex items-center outline-none border rounded-md p-2 gap-x-1"
              >
                <img src="../../assets/lock.svg" class="h-5 w-5" alt="" />
                <input
                  type="text"
                  required
                  v-model="update.old"
                  placeholder="Enter old pasword"
                  class="rounded-md bg-white placeholder:text-gray-400 outline-none w-full"
                />
              </div>
            </div>
            <div class="flex flex-col gap-y-1">
              <label for=""
                >New Password<sup class="text-red-500">*</sup></label
              >
              <div
                class="flex items-center outline-none border rounded-md p-2 gap-x-1"
              >
                <img src="../../assets/lock.svg" class="h-5 w-5" alt="" />
                <input
                  type="text"
                  required
                  v-model="update.new"
                  placeholder="Enter new password"
                  class="rounded-md bg-white placeholder:text-gray-400 outline-none w-full"
                />
              </div>
            </div>

            <div class="flex flex-col gap-y-1">
              <label for=""
                >Confirm Password<sup class="text-red-500">*</sup></label
              >
              <div
                class="flex items-center outline-none border rounded-md p-2 gap-x-1"
              >
                <img src="../../assets/lock.svg" class="h-5 w-5" alt="" />
                <input
                  type="text"
                  v-model="confirm"
                  placeholder="Confirm the new password"
                  class="rounded-md bg-white placeholder:text-gray-400 outline-none w-full"
                />
              </div>
            </div>
            <p
              v-if="this.message.text != ''"
              class="text-start text-xs md:text-md"
              :class="[
                this.message.status == 200
                  ? ' text-green-400'
                  : ' text-red-400',
              ]"
            >
              {{ this.message.text }}
            </p>
            <div class="flex justify-center items-center">
              <button
                type="submit"
                class="rounded-md p-2 text-center shadow-lg hover:shadow-none w-20 bg-[#2A84EF] text-white"
                :class="{ 'opacity-50 cursor-not-allowed': loading }"
                :disabled="loading"
              >
                <span v-if="!loading">Submit</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "../SideNav.vue";
import TopBar from "../TopBar.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "UpdatePassword",
  data() {
    return {
      update: {
        old: "",
        new: "",
      },
      message: {
        text: "",
        status: "",
      },
      confirm: "",
      loading: false,
    };
  },

  components: {
    SideNav,
    TopBar,
  },

  computed: {
    ...mapGetters(["user", "getToken"]),
  },

  methods: {
    updatePassword() {
      if (this.confirm == this.update.new) {
        let Json = {
          old_password: this.update.old,
          new_password: this.update.new,
        };
        axios
          .put(
            `${process.env.VUE_APP_API}/api/change-password/?Key=${this.getToken}`,
            Json
          )
          .then((response) => {
            if (response.status == 200) {
              console.log(response.data);
              this.loading = true;
              this.message.text = "Password changed successfully";
              this.message.status = 200;
              setTimeout(() => {
                this.loading = false;
                this.message.text = "";
                this.message.status = "";
              }, 3000);
            } else {
              console.log("Check the Provided credentials");
              this.message.text = "Check the credentials";
              this.message.status = 400;
              setTimeout(() => {
                this.loading = false;
                this.message.text = "";
                this.message.status = "";
              }, 3000);
            }
          });
      } else {
        console.log("Password Not Same");
        this.loading = true;
        this.message.text = "New password and confirm password are not same";
        this.message.status = 400;
        setTimeout(() => {
          this.loading = false;
          this.message.text = "";
          this.message.status = "";
        }, 3000);
      }
    },
  },
};
</script>

<style></style>
