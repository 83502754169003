<template>
  <div class="flex h-screen roboto-light">
    <!-- Sidebar -->
    <div
      :class="[
        'bg-[#ffffff]',
        'text-white',
        'w-64',
        { hidden: isSidebarOpen },
        { 'md:block': isSidebarOpen },
      ]"
      class="w-64 flex flex-col"
    >
      <!-- Logo -->
      <div class="p-4 flex items-center">
        <img src="../assets/Logo.svg" alt="Logo" class="w-2/3" />
      </div>
      <!-- Navigation Links -->
      <div class="flex flex-col gap-y-1">
        <router-link
          v-if="getUser.permission == 'admin'"
          to="/admin-dashboard"
          class="flex items-center gap-x-3 py-2 pl-2 text-[#54595F] text-sm hover:bg-gray-100"
        >
          <img
            v-if="this.$route.name === 'AdminDash'"
            src="../assets/dashboard_active.png"
            class=""
            alt=""
          />
          <img v-else src="../assets/dashboard.svg" class="" alt="" />
          <!--<img :src="$router.name === 'AdminDash' ? '../assets/dashboard_active.png' : '../assets/dashboard_inactive.png'" class="" alt="" /> -->
          Dashboard</router-link
        >
        <router-link
          v-else
          to="/user-dashboard"
          class="flex items-center gap-x-3 py-2 pl-2 text-[#54595F] text-sm hover:bg-gray-100"
        >
          <img
            v-if="this.$route.name === 'userdash'"
            src="../assets/dashboard_active.png"
            class=""
            alt=""
          />
          <img v-else src="../assets/dashboard.svg" class="" alt="" />
          <!--<img :src="$router.name === 'AdminDash' ? '../assets/dashboard_active.png' : '../assets/dashboard_inactive.png'" class="" alt="" /> -->
          Dashboard</router-link
        >
        <router-link
          to="/download"
          class="flex items-center gap-x-3 py-2 pl-2 text-[#54595F] text-sm hover:bg-gray-100"
        >
          <font-awesome-icon
            :icon="['fas', 'download']"
            :class="{ 'text-primary': $route.name == 'DownloadPage' }"
            class=""
          />
          Download LIVEYfy</router-link
        >
        <router-link
          to="/manual"
          class="flex items-center gap-x-3 py-2 pl-2 text-[#54595F] text-sm hover:bg-gray-100"
        >
          <img
            v-if="this.$route.name === 'ManualView'"
            src="../assets/google-docs-blue.png"
            class=""
            alt=""
          />
          <img v-else src="../assets/google-docs.png" alt="" />
          Manual
        </router-link>
        <template v-if="isAuthenticated && this.getUser.permission == 'admin'">
          <router-link
            to="/settings"
            class="flex items-center gap-x-3 py-2 pl-2 text-[#54595F] text-sm hover:bg-gray-100"
          >
            <img
              v-if="this.$route.name === 'SettingView'"
              src="../assets/settings_active.png"
              class=""
              alt=""
            />
            <img v-else src="../assets/settings_inactive.png" alt="" />
            Settings
          </router-link>
        </template>
      </div>
      <div
        class="block"
        v-if="
          this.getUser.permission == 'external' ||
          this.getUser.permission == 'admin'
        "
      >
        <router-link
          :to="{ name: 'SubscriptionView' }"
          class="flex bg-black items-center justify-center text-white gap-x-2 rounded-md p-2 absolute bottom-5 left-5 z-40"
        >
          <img src="../assets/crown.png" class="h-4 w-4" alt="" />
          <p>Manage Subscription</p>
        </router-link>
      </div>
    </div>

    <!-- Burger Icon -->
    <button
      @click="toggleSidebar"
      class="md:hidden text-black right-5 fixed top-4"
    >
      <img src="../assets/menu.png" alt="" class="w-8 h-8" />
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isSidebarOpen: true,
      isProfileMenuOpen: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters(["getUser", "isAuthenticated"]),
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleProfileMenu() {
      this.isProfileMenuOpen = !this.isProfileMenuOpen;
    },
    closeProfileMenu(event) {
      if (!event.target.closest(".relative")) {
        this.isProfileMenuOpen = false;
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.closeProfileMenu);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.closeProfileMenu);
  },
};
</script>
