<template>
  <div class="flex">
    <SideNav />
    <div class="bg-[#f1f8fe] h-screen w-full">
      <TopBar />
      <div class="h-[95%] overflow-y-scroll w-full">
        <div class="flex flex-col p-4 md:px-8">
        <ManualDetail />
      </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";
import TopBar from "@/components/TopBar.vue";
import ManualDetail from "@/components/Manual/ManualDetail.vue";
export default {
  name: "SettingsView",
  components: { SideNav, TopBar, ManualDetail },
  data() {
    return {};
  },
};
</script>

<style></style>
