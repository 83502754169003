<template>
  <p
    class="w-full text-center bottom-0 p-5 text-xs md:text-sm text-gray-500 roboto-light"
  >
    By signing up you agree to the
    <router-link :to="{ name: 'TermsandConditions' }">
      <span class="underline font-semibold roboto-regular">Terms of Use</span>
    </router-link>
    and
    <router-link
      :to="{ name: 'PrivacyPolicy' }"
      target="_blank"
      class="underline font-semibold roboto-medium"
      >Privacy Policy</router-link
    >.
  </p>
</template>
