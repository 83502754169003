<template>
  <div
    class="flex flex-col items-center gap-y-3 justify-center h-screen bg-white relative"
  >
    <img src="../../assets/Logo.svg" class="h-8 md:h-10" alt="" />
    <h1 class="text-2xl font-bold">Reset Password</h1>
    <p>Regain Access to Your Account Securely</p>
    <form
      @submit.prevent="resetPassword"
      class="flex flex-col gap-y-5 w-44 md:w-80"
    >
      <!-- <div
        class="flex w-full items-center border border-gray-400 rounded-md gap-x-2 p-1"
      >
        <img src="../../assets/mail.svg" class="h-5 w-5" alt="" />
        <input
          type="email"
          placeholder="Enter your register email"
          name=""
          v-model="email"
          class="outline-none rounded-md w-[85%] placeholder:text-xs"
          id=""
        />
      </div> -->

      <div
        class="flex w-full items-center border border-gray-400 rounded-md gap-x-2 p-1"
      >
        <img src="../../assets/lock.svg" class="h-5 w-5" alt="" />
        <input
          type="password"
          placeholder="Enter your new password"
          name=""
          class="outline-none rounded-md w-[85%] placeholder:text-xs"
          id="new_password"
          v-model="new_pass"
          required
        />
      </div>

      <div
        class="flex w-full items-center border border-gray-400 rounded-md gap-x-2 p-1"
      >
        <img src="../../assets/lock.svg" class="h-5 w-5" alt="" />
        <input
          type="password"
          placeholder="Confirm Password"
          name=""
          class="outline-none rounded-md w-[85%] placeholder:text-xs"
          v-model="confirm_pass"
          id="confirm_password"
          required
        />
      </div>
      <p
        v-if="this.message.text != ''"
        class="text-start"
        :class="[
          this.message.status == 200 ? ' text-green-700' : ' text-red-700',
        ]"
      >
        {{ this.message.text }}
      </p>

      <button
        v-if="!loading"
        type="submit"
        class="bg-[#2A84EF] rounded-md text-white p-1"
      >
        Reset Password
      </button>
      <button v-else class="bg-[#2A84EF] rounded-md text-white p-1">
        Loading...
      </button>
      <router-link to="/" class="border rounded-md p-1 text-center"
        >Cancel</router-link
      >
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "changePassword",
  data() {
    return {
      new_pass: "",
      confirm_pass: "",
      message: {
        text: "",
        status: "",
      },
      loading: false,
    };
  },

  methods: {
    resetPassword() {
      this.loading = true;
      let json_data = {
        new_password: this.new_pass,
        confirm_password: this.confirm_pass,
      };
      console.log(json_data);
      axios
        .post(
          `${process.env.VUE_APP_API}/reset-password/${this.$route.params.id}/${this.$route.params.token}/`,
          json_data
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == 200) {
            this.message.text = "Password Updated";
            this.message.status = 200;
            setTimeout(() => {
              this.message.text = "";
              this.message.status = "";
              this.loading = false;
              this.$router.push("/");
            }, 3000);
          } else {
            this.message.text = "Confirm Password Not Match";
            this.message.status = 400;
            this.loading = true;
            setTimeout(() => {
              this.message.text = "";
              this.loading = false;
              this.message.status = "";
            }, 5000);
          }
        })
        .catch((er) => {
          console.log(er);
        });
    },
  },
};
</script>
