<template>
  <div class="flex items-center justify-center h-screen">
    <div class="rounded shadow-lg min-w-[520px] p-6">
      <div class="flex flex-col items-center space-y-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="text-green-600 w-28 h-28"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h1 class="text-4xl font-bold">Thank You !</h1>
        <h1 class="text-4xl font-bold">{{ paymentStatus }}</h1>
        <p>Thank you for your interest!.</p>
        <a
          class="inline-flex items-center px-4 py-2 text-white bg-[#2A84EF] border border-[#2A84EF] rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-3 h-3 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
          <a
            v-if="getUser.permission == 'admin'"
            href="/admin-dashboard"
            class="px-6 py-3 bg-blue-500 text-white rounded-lg inline-block"
            >Back to Dashboard</a
          >
          <a
            v-else-if="getUser.permission == 'external'"
            href="/user-dashboard"
            class="px-6 py-3 bg-blue-500 text-white rounded-lg inline-block"
            >Back to Dashboard</a
          >
          <a
            v-else
            href="/"
            class="px-6 py-3 bg-blue-500 text-white rounded-lg inline-block"
          >
            Home
          </a>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      paymentStatus: "Pending",
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  async mounted() {
    // Get session_id from URL parameters
    const sessionId = new URLSearchParams(window.location.search).get(
      "session_id"
    );

    if (sessionId) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API}/payment-status/${sessionId}/`
        );
        const result = await response.json();

        if (result.payment_status === "succeeded") {
          this.paymentStatus = "Payment Successful";
        } else {
          this.paymentStatus = "Payment Failed";
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
        this.paymentStatus = "Error occurred";
      }
    }
  },
};
</script>
