<template>
  <div class="mx-auto max-w-[1200px] p-5 roboto-light flex flex-col gap-3">
    <img
      src="../../assets/Logo.svg"
      class="h-20 w-full bg-gray-200 p-2"
      alt=""
    />
    <div>
      <h1 class="text-lg font-normal">Terms and Conditions</h1>
      <p class="text-gray-700 text-sm">
        Welcome to LIVEYfy.com ("we," "us," or "our"). By downloading,
        installing, or using our AI-based online meeting background noise
        removing desktop application (the "Application"), you agree to comply
        with and be bound by these Terms and Conditions ("Terms"). If you do not
        agree to these Terms, please do not use the Application.
      </p>
    </div>
    <div>
      <h1 class="text-lg font-normal">1. License</h1>
      <p class="text-gray-700 text-sm">
        We grant you a limited, non-exclusive, non-transferable, and revocable
        license to use the Application for personal or business use in
        accordance with these Terms. This license does not allow you to
        distribute, modify, or create derivative works based on the Application.
      </p>
    </div>
    <div>
      <h1 class="text-lg font-normal">2. User Responsibilities</h1>
      <p class="text-gray-700 text-sm">
        By using the Application, you agree to:
      </p>
      <ul>
        <li class="text-gray-700 text-sm">
          Use the Application only for lawful purposes and in accordance with
          these Terms.
        </li>
        <li class="text-gray-700 text-sm">
          Not use the Application in any manner that could disable, overburden,
          damage, or impair the Application or interfere with any other party’s
          use of the Application.
        </li>
        <li class="text-gray-700 text-sm">
          Not attempt to gain unauthorized access to any part of the
          Application, other accounts, computer systems, or networks connected
          to the Application.
        </li>
        <li class="text-gray-700 text-sm">
          Not use any automated means, including robots, scripts, or spiders, to
          access, monitor, or copy any part of the Application.
        </li>
      </ul>
    </div>
    <div>
      <h1 class="text-lg font-normal">3. Intellectual Property</h1>
      <p class="text-gray-700 text-sm">
        All content, features, and functionality of the Application, including
        but not limited to text, graphics, logos, and software, are the
        exclusive property of LIVEYfy.com and are protected by international
        copyright, trademark, patent, trade secret, and other intellectual
        property or proprietary rights laws.
      </p>
    </div>

    <div>
      <h1 class="text-lg font-normal">4. Privacy</h1>
      <p class="text-gray-700 text-sm">
        Your use of the Application is also governed by our Privacy Policy,
        which can be found here
        <router-link
          :to="{ name: 'PrivacyPolicy' }"
          target="_blank"
          class="underline font-normal text-blue-500"
          >Privacy Policy</router-link
        >. Please review our Privacy Policy to understand our practices
        regarding the collection, use, and disclosure of your personal
        information.
      </p>
    </div>
    <div>
      <h1 class="text-lg font-normal">5. Disclaimer of Warranties</h1>
      <p class="text-gray-700 text-sm">
        The Application is provided "as is" and "as available," without any
        warranties of any kind, either express or implied, including but not
        limited to the implied warranties of merchantability, fitness for a
        particular purpose, and non-infringement. We do not warrant that the
        Application will be uninterrupted, error-free, or free of viruses or
        other harmful components.
      </p>
    </div>
    <div>
      <h1 class="text-lg font-normal">6. Limitation of Liability</h1>
      <p class="text-gray-700 text-sm">
        To the fullest extent permitted by law, in no event will LIVEYfy.com,
        its affiliates, or their licensors, service providers, employees,
        agents, officers, or directors be liable for any indirect, incidental,
        special, consequential, or punitive damages, including but not limited
        to lost profits, data loss, or other damages arising out of or related
        to your use of or inability to use the Application.
      </p>
    </div>
    <div>
      <h1 class="text-lg font-normal">7. Indemnification</h1>
      <p class="text-gray-700 text-sm">
        You agree to defend, indemnify, and hold harmless LIVEYfy.com and its
        affiliates, licensors, and service providers...
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
