<template>
  <div class="flex">
    <SideNav />
    <div class="bg-[#f1f8fe] h-screen w-full">
      <TopBar />
      <div class="py-4">
        <IntentButton />
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import SideNav from "@/components/SideNav.vue";
import IntentButton from "@/components/StripeIntegration.vue";

export default {
  data() {
    return {};
  },
  components: {
    SideNav,
    TopBar,
    IntentButton,
  },
};
</script>
