<!-- PaymentComponent.vue -->
<template>
  <div
    class="max-w-[480px] mx-auto h-auto p-8 shadow-xl border border-gray-200 rounded-md"
  >
    <form @submit.prevent="handleSubmit" class="flex flex-col gap-2 h-full">
      <img
        src="@/assets/Logo.svg"
        alt="LIVEYfy LOGO"
        class="object-contain h-[40px]"
      />
      <!-- <h1 class="py-2">LIVEY TECHNOLOGIES LLC</h1> -->
      <div class="flex flex-col gap-4 py-4">
        <p class="flex justify-start">
          <span class="w-[75%] flex justify-start items-center gap-2">
            <img src="@/assets/bag.png" alt="" class="w-4 h-4 object-contain" />
            Actual Prize
          </span>
          <span class="w-[20%] flex justify-end">
            ${{ schemePlan[0]?.marked_price }}</span
          >
        </p>
        <p class="flex justify-start">
          <span class="w-[75%] flex justify-start items-center gap-2">
            <img src="@/assets/tag.png" alt="" class="w-4 h-4 object-contain" />
            Discount
            <span class="text-green-700"
              >({{ schemePlan[0]?.discount_percentage }})%</span
            >
          </span>
          <span class="w-[20%] flex justify-end text-green-700">
            ${{
              (
                (schemePlan[0]?.marked_price / 100) *
                schemePlan[0]?.discount_percentage
              ).toFixed(2)
            }}</span
          >
        </p>

        <hr />

        <p class="flex justify-start pb-2">
          <span class="w-[75%] flex justify-start items-center gap-2">
            <img
              src="@/assets/credit-card.png"
              alt=""
              class="w-4 h-4 object-contain"
            />
            To pay
          </span>
          <span class="w-[20%] flex justify-end font-semibold">
            ${{ schemePlan[0]?.selling_price }}</span
          >
        </p>
      </div>
      <div id="card-element"></div>
      <button
        type="submit"
        :disabled="processing"
        class="bg-sky-600 px-4 py-1 text-white rounded-md shadow-sm"
      >
        {{ processing ? "Processing…" : "Pay" }}
      </button>
    </form>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      stripe: null,
      card: null,
      clientSecret: "",
      processing: false,
      error: "",
      productDetails: {
        name: "LIVEYfy",
        description: "Organization Subscription Plan",
      },
      quantity: 1,
      userDetails: { name: "", email: "" },
      orderId: null,
      selected_plan: "",
      schemePlan: [],
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  created() {
    this.handleCardChange();
    // if (sessionStorage.getItem("plan_info")) {
    //   this.selected_plan = sessionStorage.getItem("plan_info");
    // }
    this.userDetails.name = this.getUser.display_name;
    this.userDetails.email = this.getUser.email;
  },
  async mounted() {
    console.log("mounted");
  },
  methods: {
    async stripeServiceCall() {
      this.stripe = await loadStripe(
        `${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`
      );
      const elements = this.stripe.elements();
      this.card = elements.create("card");
      this.card.mount("#card-element");
      const response = await fetch(
        `${process.env.VUE_APP_API}/api/create-payment-intent/`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            product_details: this.productDetails,
            quantity: this.quantity,
            user_details: this.userDetails,
            user_id: this.getUser.id,
            subscription_id: this.schemePlan[0]?.id,
          }),
        }
      );
      const data = await response.json();
      this.clientSecret = data.clientSecret;
      this.orderId = data.orderId;
    },
    async handleCardChange() {
      axios
        .get(
          `${process.env.VUE_APP_API}/api/list-subscriptions/?title=${this.$route.params.plan_type}`
        )
        .then((response) => {
          this.schemePlan = response.data.results;
          this.stripeServiceCall();
          if (this.schemePlan.length == 0) {
            this.$router.push({ name: "PageNotFound" });
          }
        })
        .catch((error) => {
          console.log(error, "hello");
        });
    },
    async handleSubmit() {
      this.processing = true;

      const { error } = await this.stripe.confirmCardPayment(
        this.clientSecret,
        {
          payment_method: {
            card: this.card,
          },
        }
      );
      console.log("response:", error);
      if (error) {
        this.error = error.message;
        this.processing = false;
      } else {
        this.error = "";
        this.processing = false;
        await fetch(
          `${process.env.VUE_APP_API}/api/update-order-status/${this.orderId}/`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ status: "succeeded" }),
          }
        );

        this.$router.push({ name: "ThankYou" });
      }
    },
  },
};
</script>

<style>
#card-element {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}
</style>
