<template>
  <div class="flex">
    <SideNav />
    <div class="bg-[#f1f8fe] h-screen w-full">
      <TopBar />
      <div class="flex flex-col p-4 md:px-8">
        <h1 class="py-4 roboto-light font-medium">
          Download History
          <span class="text-[#2a84ef]">({{ downloadCount }})</span>
        </h1>
        <table class="w-full text-left">
          <thead class="p-5">
            <tr class="bg-[#2a84ef] rounded-xl h-10 px-2 text-white">
              <th class="p-2 text-sm roboto-light font-medium rounded-md">
                Name
              </th>
              <th class="p-2 text-sm roboto-light font-medium rounded-md">
                Email
              </th>
              <th class="p-2 text-sm roboto-light font-medium rounded-md">
                Device
              </th>
              <th class="p-2 text-sm roboto-light font-medium rounded-md">
                Date
              </th>
            </tr>
          </thead>
          <tbody class="w-full">
            <template v-if="userDownloads.length > 0">
              <tr
                v-for="el in userDownloads"
                :key="el"
                class="text-left py-2 border rounded-lg"
              >
                <td class="p-2 text-sm">{{ el.username }}</td>
                <td class="p-2 text-sm">{{ el.email }}</td>
                <td class="p-2 text-sm">{{ el.device }}</td>
                <td class="p-2 text-sm">
                  {{ new Date(el.download_date_time).toLocaleString("en-GB") }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="text-left py-2 border rounded-lg">
                <td colspan="5" class="p-2 text-sm text-center">
                  No data Found
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import SideNav from "@/components/SideNav.vue";
import TopBar from "@/components/TopBar.vue";
import axios from "axios";
import { ref, onMounted, defineProps } from "vue";

var userDownloads = ref([]);
var downloadCount = ref(0);

onMounted(() => {
  userDownloadData();
});

const props = defineProps(["id"]);
console.log(props.id);
function userDownloadData() {
  axios
    .get(
      `${process.env.VUE_APP_API}/api/list-download-history/?user_id=${props.id}`
    )
    .then((response) => {
      downloadCount.value = response.data.count;
      console.log("Download count:", downloadCount.value);
      userDownloads.value = response.data.results;
      console.log(response);
      console.log("Data fetched successfully", userDownloads.value);
    })
    .catch((error) => {
      console.error(error);
    });
}
</script>

<style></style>
